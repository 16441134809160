import { getEmployee } from "@actions/Employees";
import { Card } from "@components/shadcn/ui/card";
import React, { useEffect, useState } from "react";
import "react-marquee-text/dist/styles.css";
import RideBadgeTrackingTransport from "./RideBadgeTrackingTransport";
import TexteScroller from "./TexteScroller";

function TransportTrackingCard({ transport, selected }) {
  const { carType, patient, driverStatus, driver } = transport;
  const { name, id: driverId } = transport.driver ?? "";
  const { address: addressFrom } = transport.from;
  const { address: addressTo } = transport.to;
  const [teamMate1, setTeamMate1] = useState(null);
  const [teamMate2, setTeamMate2] = useState(null);
  useEffect(() => {
    driver?.teamMate1Id &&
      getEmployee(driver?.teamMate1Id).then((emp) => setTeamMate1(emp));
    driver?.teamMate2Id &&
      getEmployee(driver?.teamMate2Id).then((emp) => setTeamMate2(emp));
  }, [driver]);
  return (
    <Card
      className={`border-bgGrey border-2 relative my-1 py-[5px] cursor-pointer ${
        selected.id === driverId && selected.isSelected ? "bg-bgGrey" : ""
      }`}
    >
      <div className="w-full  flex flex-col py-1 relative">
        <div className="w-full h-auto rounded-3xl flex flex-row px-3 items-center">
          <div className="w-1/12"></div>
          <div
            style={{ width: "22px" }}
            className={
              carType === "VSL" ? "absolute top-[14px]" : "absolute top-[10px]"
            }
          >
            {carType === "VSL" ? (
              <img
                style={{ alignSelf: "center", width: "22px" }}
                src="/icons/veicule_info_modal/vslcar.svg"
              />
            ) : (
              carType === "AMBULANCE" && (
                <img width={22} src="/icons/veicule_info_modal/ambulance.svg" />
              )
            )}
          </div>
          <div className="w-7/12 pl-2 flex flex-col items-start">
            <div className="flex">
              {carType === "AMBULANCE" ? (
                <p
                  style={{
                    height: "fit-content",
                    marginRight: 4,
                    fontSize: 12,
                    fontStyle: "italic",
                    color: "#4951ff",
                    fontWeight: "bold"
                  }}
                >
                  AMB
                </p>
              ) : (
                <p
                  style={{
                    height: "fit-content",
                    marginRight: 4,
                    fontSize: 12,
                    fontStyle: "italic",
                    color: "#FFB960",
                    fontWeight: "bold"
                  }}
                >
                  {carType}
                </p>
              )}

              <p
                style={{
                  height: "fit-content",
                  fontSize: 16,
                  lineHeight: 1,
                  fontWeight: "bold",
                  color: "#02196C",
                  textTransform: "capitalize"
                }}
              >
                {`${name}`}
              </p>
            </div>
          </div>
          <div className="w-3/12">
            <div className="flex justify-center">
              <RideBadgeTrackingTransport
                containerClassName="bg-bgGrey flex flex-row item-center justify-center border-2 border-borderV2 rounded-lg cursor-pointer"
                imgWidth="7px"
                imgSrc="/icons/veicule_info_modal/dot_red.svg"
                text="En course"
                textClassName="whitespace-nowrap text-festiveFennec"
              />
            </div>
          </div>
        </div>
        <div className="container flex flex-row">
          <div className="w-1/12"></div>
          <div className="w-10/12 pl-4">
            <div className="flex flex-row items-baseline">
              <p
                style={{
                  marginRight: 4,
                  fontSize: 12,
                  color: "#02196C60",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  height: "fit-content",
                  lineHeight: 0.8,
                  whiteSpace: "nowrap"
                }}
              >
                <TexteScroller className="w-[262px] pr-1">
                  {/* Render teamMate1 if available */}
                  {teamMate1 && (
                    <>
                      {teamMate1.firstName} {teamMate1.lastName}
                    </>
                  )}

                  {/* Only show the dash if both teammates exist */}
                  {teamMate1 && teamMate2 && " - "}

                  {/* Render teamMate2 if available */}
                  {teamMate2 && (
                    <span style={{ color: "#DB2C73" }}>
                      {teamMate2.firstName} {teamMate2.lastName}
                    </span>
                  )}
                </TexteScroller>
              </p>
            </div>
          </div>
        </div>

        <div className=" px-3 mt-[9px]">
          <div className="flex flex-row items-baseline">
            <div className="flex items-center h-fit">
              <img
                width={11}
                className="mr-1"
                src="/icons/transport_tracking/location_tracking.svg"
              />
              <p
                style={{
                  fontSize: 12,
                  lineHeight: 1,
                  height: "fit-content"
                }}
                className="text-lightBlue"
              >
                <TexteScroller className="w-[282px]">
                  {addressFrom}
                </TexteScroller>
              </p>
            </div>
          </div>
          <div className="flex flex-row items-baseline">
            <div className="flex items-center">
              <img
                width={11}
                className="mr-1"
                src="/icons/transport_tracking/navigation_tracking.svg"
              />
              <div
                style={{ fontSize: 12, lineHeight: 1.5 }}
                className="text-lightBlue"
              >
                {driverStatus === "RETRIEVING_PATIENT" && (
                  <>
                    <TexteScroller className="w-[282px]">
                      En direction du patient
                      <span className="text-festiveFennec">
                        {` ${patient.firstName} ${patient.lastName}`}
                      </span>
                    </TexteScroller>
                  </>
                )}
                {driverStatus === "DEPOSITING_PATIENT" && (
                  <>
                    <TexteScroller className="w-[282px]">
                      Vers le lieu de RDV
                      <span className="text-festiveFennec">{` ${addressTo}`}</span>
                    </TexteScroller>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
export default TransportTrackingCard;
