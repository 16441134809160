import { useZustandStore } from "@helpers/store";
import moment from "moment";
import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { useGlobal } from "reactn";
import TransportBourseCard from "./TransportBourseCard";
function TransportBourseList({ transports }) {
  const [transportSelected, setTransportSelected] =
    useGlobal("transportSelected");
  const setBourseTransport = useZustandStore(
    (state) => state.setBourseTransport
  );
  const setGlobalRef = useZustandStore((state) => state.setGlobalRef);

  const setIsBourseModalOpen = useZustandStore(
    (state) => state.setIsBourseModalOpen
  );
  const setIsDriverModalOpen = useZustandStore(
    (state) => state.setIsDriverModalOpen
  );
  const setIsRegulationModalOpen = useZustandStore(
    (state) => state.setIsRegulationModalOpen
  );
  const setIsOpen = useZustandStore((state) => state.setIsOpen);
  const setTransportId = useZustandStore((state) => state.setTransportId);
  const [isSelected, setIsSelected] = useState(false);
  const filteredTransports = transports
    .map((transport) => {
      if (transport.goToTransportId) {
        // Handle return transport visibility: show if corresponding Aller is ENDED
        const correspondingAllerTransport = transports.find(
          (t) => t.id === transport.goToTransportId
        );
        if (
          correspondingAllerTransport &&
          correspondingAllerTransport.status === "ENDED"
        ) {
          return { ...transport, hidden: false }; // Show Retour if Aller is ENDED
        }
        return { ...transport, hidden: true }; // Hide Retour if Aller is not ENDED
      }
      return transport; // Return the transport as-is if no return transport
    })
    .filter((transport) => !transport.hidden); // Exclude hidden transports
  return (
    <div style={{ height: "574px" }} className={`custom-scrollbar`}>
      {filteredTransports.length > 0 &&
        filteredTransports
          .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
          .map((item, i) => {
            const prevStartDate = i > 0 ? transports[i - 1].startDate : null;
            const isSameAsPrevDay =
              prevStartDate &&
              moment(prevStartDate).isSame(item.startDate, "d");
            return (
              <div
                onClick={(e) => {
                  setBourseTransport(item);
                  setTransportSelected(item);
                  setGlobalRef(e.target);
                  setIsBourseModalOpen(true);
                  setIsRegulationModalOpen(false);
                  setIsOpen(false);
                  setIsDriverModalOpen([false, false]);
                  setTransportId(item.id);
                  setIsSelected(true);
                }}
                style={{ width: "320px" }}
                key={item.id}
              >
                {!isSameAsPrevDay && (
                  <VisibilitySensor offset={{ top: 300 }} partialVisibility>
                    <div
                      className="capitalize"
                      id={moment(item.startDate).format("YYYY-MM-DD")}
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        marginBottom: 5,
                        marginTop: i !== 0 ? 5 : 0,
                        color: "#02196C"
                      }}
                    >
                      {moment(item.startDate).format("dddd DD MMMM YYYY")}
                    </div>
                  </VisibilitySensor>
                )}

                <TransportBourseCard
                  isSelected={isSelected}
                  key={item.id}
                  bourseTransport={item}
                />
              </div>
            );
          })}
    </div>
  );
}

export default TransportBourseList;
