import { Card } from "@components/shadcn/ui/card";
import moment from "moment";
import React from "react";
import RideBadge from "./RideBadge";
import TexteScroller from "./TexteScroller";
function TransportRegulationCard({ transport, selectedTransport }) {
  const {
    carType,
    startDate,
    endDate,
    status,
    regulatedBy,
    driverStatus,
    isAssign,
    patient,
    returnTransportId,
    goToTransportId
  } = transport;
  const { firstName, lastName } = transport.patient;
  const {
    postalCode: postalCodeFrom,
    city: cityFrom,
    name: nameFrom
  } = transport.from;
  const { postalCode: postalCodeTo, city: cityTo, name: nameTo } = transport.to;
  const regulationCardstyle = [
    {
      cardStyle: `bg-white border-2 ${
        selectedTransport.id === transport.id && selectedTransport.isSelected
          ? "border-borderV2 border-2"
          : "border-bgGray"
      } relative my-2 py-[5px] px-3 cursor-pointer`,

      containerClassName:
        "flex flex-row item-center justify-end text-end w-full",
      imgSrc: "/icons/veicule_info_modal/dot_blue.svg",
      text: "A réguler",
      textClassName: "whitespace-nowrap text-skyBlue2 pl-[5px] !text-[12px]",
      subTextClassName: "",
      subText: ""
    },
    {
      cardStyle: `bg-cosmicLatte border-2 border-transparent ${
        selectedTransport.id === transport.id && selectedTransport.isSelected
          ? "border-chateauGreen50 border-2"
          : ""
      } relative my-2 py-[5px] px-3 cursor-pointer`,
      containerClassName:
        "flex flex-row item-center justify-end text-end w-full",
      imgSrc: "/icons/transport_regulation/check.svg",
      text: "Terminé",
      textClassName:
        "whitespace-nowrap text-chateauGreen pl-[5px] !text-[12px]",
      subTextClassName: "font-thin text-chateauGreen text-end",
      subText: "Le"
    },
    {
      cardStyle: `bg-lavenderBlush border-transparent border-2 ${
        selectedTransport.id === transport.id && selectedTransport.isSelected
          ? "border-pink50 border-2"
          : ""
      }  relative my-2 py-[5px] px-3 cursor-pointer`,
      containerClassName:
        "flex flex-row item-center justify-end text-end w-full",
      imgSrc: "/icons/transport_regulation/x.svg",
      text: "Annulé",
      textClassName: "whitespace-nowrap text-pink pl-[5px] !text-[12px]",
      subTextClassName: "font-thin text-pink text-end",
      subText: ""
    },
    {
      cardStyle: `bg-cornSilk border-2 border-transparent ${
        selectedTransport.id === transport.id && selectedTransport.isSelected
          ? "border-selectiveYellow50 border-2"
          : ""
      }  relative my-2 py-[5px] px-3 cursor-pointer`,
      containerClassName:
        "flex flex-row item-center justify-end text-end w-full",
      imgSrc: "/icons/transport_regulation/target.svg",
      text: "Bourse",
      textClassName:
        "whitespace-nowrap text-selectiveYellow pl-[5px] !text-[12px]",
      subTextClassName: "font-thin text-selectiveYellow  text-end",
      subText: "Le"
    },
    {
      cardStyle: `bg-snow80 border-transparent border-2 ${
        selectedTransport.id === transport.id && selectedTransport.isSelected
          ? "border-festiveFennec50 border-2"
          : ""
      } relative my-2 py-[5px] px-3 cursor-pointer`,
      containerClassName:
        "flex flex-row item-center justify-end text-end w-full",
      imgSrc: "",
      text: "En cours",
      textClassName:
        "whitespace-nowrap text-festiveFennec pl-[5px] !text-[12px]",
      subTextClassName: "font-thin text-festiveFennec text-end",
      subText: "Vers le patient"
    },
    {
      cardStyle: `bg-skyBlue10 border-transparent border-2 ${
        selectedTransport.id === transport.id && selectedTransport.isSelected
          ? "border-skyBlue50 border-2"
          : ""
      } relative  my-2 py-[5px] px-3 cursor-pointer`,
      containerClassName:
        "flex flex-row item-center justify-end text-end w-full",
      imgSrc: "",
      text: "Nouveau",
      textClassName: "whitespace-nowrap text-neonBlue pl-[5px] !text-[12px]",
      subTextClassName: "font-thin text-neonBlue text-end",
      subText: ""
    },
    {
      cardStyle: `bg-electricIndigo10 border-transparent border-2 ${
        selectedTransport.id === transport.id && selectedTransport.isSelected
          ? "border-electricIndigo50 border-2"
          : ""
      } relative   my-2 py-[5px] px-3 cursor-pointer`,
      containerClassName:
        "flex flex-row item-center justify-end text-end w-full",
      imgSrc: "",
      text: "Attribué",
      textClassName: "whitespace-nowrap text-neonBlue pl-[5px] !text-[12px]",
      subTextClassName: "font-thin text-neonBlue",
      subText: "En attente"
    },
    {
      cardStyle: `bg-electricIndigo10 border-skyBlue50 border-transparent border-2 ${
        selectedTransport.id === transport.id && selectedTransport.isSelected
          ? "border-electricIndigo50 border-2"
          : ""
      } relative  my-2 py-[5px] px-3 cursor-pointer`,
      containerClassName:
        "flex flex-row item-center justify-end text-end w-full",
      imgSrc: "",
      text: "Attribué",
      textClassName: "whitespace-nowrap text-neonBlue pl-[5px] !text-[12px]",
      subTextClassName: "font-thin text-neonBlue",
      subText: "Accepté"
    },
    {
      cardStyle: `bg-electricIndigo10 border-skyBlue50 border-transparent border-2 relative my-2 py-[5px] px-3 cursor-pointer`,
      containerClassName:
        "flex flex-row item-center justify-end text-end w-full",
      imgSrc: "",
      text: "Inconnu",
      textClassName: "whitespace-nowrap text-neonBlue pl-[5px] !text-[12px]",
      subTextClassName: "font-thin text-neonBlue",
      subText: "Accepté"
    }
  ];
  const regulationCardstyleFilter = (status) => {
    if (status === "ACCEPTED" && !isAssign) {
      return regulationCardstyle[0];
    } else if (status === "CANCELED") {
      return regulationCardstyle[2];
    } else if (status === "ACCEPTED" && driverStatus === "PENDING") {
      return regulationCardstyle[6];
    } else if (status === "ACCEPTED" && driverStatus === "ACCEPTED") {
      return regulationCardstyle[7];
    } else if (status === "ENDED") {
      return regulationCardstyle[1];
    } else if (status === "IN_PROGRESS") {
      return regulationCardstyle[4];
    } else if (
      status === "PENDING" &&
      regulatedBy === "BOURSE" &&
      isAssign === false
    ) {
      return regulationCardstyle[3];
    } else if (
      status === "PENDING" &&
      regulatedBy === "BOURSE" &&
      isAssign === null
    ) {
      return regulationCardstyle[5];
    } else {
      return regulationCardstyle[8];
    }
  };
  const {
    cardStyle,
    containerClassName,
    imgSrc,
    text,
    textClassName,
    subTextClassName,
    subText,
    imgClassName
  } = transport && regulationCardstyleFilter(status);

  return (
    <Card
      className={`${cardStyle} ${
        selectedTransport.id === transport.id &&
        "shadow-[0px_4px_4px_0px_#00000025]"
      }`}
    >
      <div className="w-full  flex flex-col">
        <div className="w-full h-auto  flex flex-row items-start">
          <div className="w-1/12  mt-[0.25rem]">
            {carType === "VSL" && (
              <img
                style={{ marginInline: "auto" }}
                className="mt-1"
                width={25}
                src="/icons/veicule_info_modal/vslcar.svg"
              />
            )}
            {carType === "AMBULANCE" && (
              <img
                style={{ marginInline: "auto" }}
                width={19}
                src="/icons/veicule_info_modal/ambulance.svg"
              />
            )}
            {carType === "AMBULANCE" ? (
              <p
                style={{
                  height: "fit-content",
                  fontSize: 10,
                  fontStyle: "italic",
                  color: "#4951ff",
                  fontWeight: "bold",
                  textAlign: "center"
                }}
              >
                AMB
              </p>
            ) : (
              <p
                style={{
                  height: "fit-content",
                  fontSize: 12,
                  fontStyle: "italic",
                  color: "#FFB960",
                  fontWeight: "bold",
                  textAlign: "center",
                  lineHeight: 1.2
                }}
              >
                {carType}
              </p>
            )}
          </div>
          <div className="w-7/12 pl-2 flex flex-col items-start">
            <div className="flex mt-[0.25rem]">
              <p
                style={{
                  height: "fit-content",
                  fontSize: 12,
                  lineHeight: 1.5,
                  fontWeight: "bold",
                  color: "#DB2C73",
                  marginRight: 5,
                  fontStyle: "italic"
                }}
              >
                {patient.gender === "male" ? "Mr" : "Mme"}
              </p>
              <p
                style={{
                  height: "fit-content",
                  fontSize: 16,
                  lineHeight: 1,
                  fontWeight: "bold",
                  color: "#02196C"
                }}
              >
                <TexteScroller className="w-[180px]">
                  {`${firstName} ${lastName}`}
                </TexteScroller>
              </p>
            </div>
            <div>
              <div className="flex flex-row items-baseline">
                {transport?.returnTransportId && (
                  <img width={15} src="/icons/veicule_info_modal/ar.svg" />
                )}

                <p
                  style={{
                    marginRight: 4,
                    fontSize: 12,
                    color: "#02196C60",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    height: "fit-content",
                    lineHeight: 0.7,
                    whiteSpace: "nowrap"
                  }}
                >
                  Transport{" "}
                  <span style={{ color: "#DB2C73" }}>
                    {returnTransportId
                      ? "Aller-Retour"
                      : goToTransportId
                      ? "Retour"
                      : "Aller"}
                  </span>
                </p>
                {transport?.goToTransportId && transport?.isReturn && (
                  <img width={15} src="/icons/veicule_info_modal/ar.svg" />
                )}
              </div>
            </div>
          </div>
          <div className="w-4/12  h-100">
            <div className="flex justify-end flex-col whitespace-nowrap mt-[0.25rem]">
              <RideBadge
                containerClassName={containerClassName}
                imgWidth="7px"
                imgSrc={imgSrc}
                imgClassName={imgClassName}
                text={text}
                textClassName={textClassName}
              />
              <p
                style={{
                  fontSize: "10px",
                  lineHeight: 0.7,
                  fontStyle: "italic",
                  textAlign: "end"
                }}
                className={subTextClassName}
              >
                {subText}
                {status === "PENDING" &&
                  regulatedBy === "BOURSE" &&
                  ` ${moment().format("DD.MM")} à ${moment().format("LT")}`}
                {status === "ACCEPTED" &&
                  driverStatus === "ACCEPTED" &&
                  isAssign &&
                  ` à ${moment().format("LT")}`}
                {status === "ACCEPTED" &&
                  driverStatus === "PENDING" &&
                  ` ${moment().format("LT")}`}
                {status === "ENDED" &&
                  ` ${moment().format("DD.MM")} à ${moment().format("LT")}`}
              </p>
            </div>
          </div>
        </div>
        <div className="my-[0.25rem]" style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <div className="w-8">
              <p
                style={{ fontSize: 12, lineHeight: 1.5 }}
                className="text-skyBlue2 font-bold text-sm"
              >
                {moment(startDate).format("LT")}
              </p>
            </div>
            <div className="w-8">
              <p
                style={{ fontSize: 12, lineHeight: 1.5 }}
                className="text-pink font-bold text-sm"
              >
                {moment(endDate).format("LT")}
              </p>
            </div>
          </div>
          <div
            className="mx-1"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingInline: 4,
              width: "fit-content"
            }}
          >
            <img width={12} src="/icons/transport_regulation/trip.svg" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center"
            }}
          >
            <div className="flex">
              <p
                style={{ fontSize: 12, lineHeight: 1.5 }}
                className="text-skyBlue90"
              >
                <TexteScroller className="w-[230px]">{`${postalCodeFrom} ${cityFrom} - ${nameFrom}`}</TexteScroller>
              </p>
            </div>
            <div className="flex">
              <p
                style={{ fontSize: 12, lineHeight: 1.5 }}
                className="text-pink90"
              >
                <TexteScroller className="w-[230px]">{`${postalCodeTo} ${cityTo} - ${nameTo}`}</TexteScroller>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default TransportRegulationCard;
