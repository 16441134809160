import React, { forwardRef, useEffect, useState } from "react";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import { ADDITIONAL_NEEDS, COMPANIONS } from "../../../../data/transports";
import Colors from "../../../../styles/Colors";
import Palette from "../../../../styles/Palette";

export default forwardRef(({ setComplement, complement }, ref) => {
  const [companion, setCompanion] = useState(null);
  const [companions, setCompanions] = useState(complement?.companions || []);
  const [moreNotes, setMoreNotes] = useState(complement?.moreNotes || null);
  const [additionalNeeds, setAdditionalNeeds] = useState(
    complement?.additionalNeeds || []
  );

  useEffect(
    () => setComplement({ companions, additionalNeeds, moreNotes }),
    [companions, additionalNeeds, moreNotes]
  );

  function addCompanion(c) {
    if (!c) return;
    const tmp = Array.from(companions);
    tmp.push(c);
    setCompanions(tmp);
  }

  // eslint-disable-next-line no-unused-vars
  function removeCompanion(c) {
    let firstFound = false;
    const tmp = Array.from(companions).filter((co) => {
      if (co === c && !firstFound) {
        firstFound = true;
        return false;
      } else {
        return true;
      }
    });
    setCompanions(tmp);
  }

  function addOrRemoveAdditionalNeed(c) {
    const tmp = Array.from(additionalNeeds);
    const alreadyIn = tmp.includes(c.value);
    if (alreadyIn) {
      setAdditionalNeeds(tmp.filter((n) => n !== c.value));
    } else {
      setAdditionalNeeds([...additionalNeeds, c.value]);
    }
  }

  return (
    <div
      className={"container flex w-[808px]"}
      style={{ justifyContent: "center" }}
    >
      <div
        style={{
          fontSize: 24,
          fontWeight: 500,
          textAlign: "center",
          marginBottom: 50
        }}
      >
        Quelles sont les spécificités du transport?
      </div>
      <div
        className={"containerRow"}
        style={{ justifyContent: "space-between" }}
      >
        <div className={"flex"}>
          <div style={{ width: 300 }}>
            <div className={"flex flex-col"} style={{ marginBottom: 30 }}>
              <div
                style={{ fontSize: 14, marginBottom: 10, textAlign: "center" }}
              >
                Ajouter un accompagnateur
              </div>
              <div className={"containerRow flex"}>
                <Input
                  required
                  dropdown
                  items={COMPANIONS}
                  value={companion}
                  onChange={setCompanion}
                  style={{ marginRight: 10, height: 30 }}
                  containerStyle={{ flex: 1, marginBottom: 10 }}
                />
                <Button
                  onClick={() => addCompanion(companion)}
                  imgLeft={"/icons/plus.png"}
                  size={"icon"}
                  containerStyle={{ height: 30 }}
                  iconStyle={{ height: 21, width: 21 }}
                />
              </div>
              {companions.map((c, i) => {
                return (
                  <div
                    key={i}
                    className={"containerRow"}
                    style={{
                      height: 30,
                      backgroundColor: Palette.blueTranslucent,
                      alignItems: "center",
                      paddingLeft: 15,
                      paddingRight: 15,
                      marginBottom: 5,
                      borderRadius: 5
                    }}
                  >
                    <div style={{ color: "#4E5E98", flex: 1 }}>{c}</div>
                    <img
                      src={"/icons/cross.png"}
                      style={{ width: 16, height: 16, cursor: "pointer" }}
                      onClick={() => removeCompanion(c)}
                    />
                  </div>
                );
              })}
            </div>
            <div className={"flex flex-col"}>
              <div
                style={{ fontSize: 14, marginBottom: 10, textAlign: "center" }}
              >
                Informations complémentaires
              </div>
              <Input
                value={moreNotes}
                onChange={setMoreNotes}
                multiline
                placeholder={
                  "Saisissez ici toute information complémentaire à communiquer au transporteur."
                }
              />
            </div>
          </div>
        </div>
        <div
          style={{
            height: "100%",
            width: 1,
            backgroundColor: Colors.input.border,
            marginLeft: 60,
            marginRight: 60
          }}
        />
        <div className={"flex"}>
          <div className={"container"} style={{ alignItems: "center" }}>
            <div style={{ fontSize: 14, marginBottom: 10 }}>Besoins annexe</div>
            <div
              className={"containerRow"}
              style={{
                flexWrap: "wrap",
                width: 255,
                justifyContent: "space-between"
              }}
            >
              {ADDITIONAL_NEEDS.map((c, i) => {
                const isSelected = additionalNeeds.find(
                  (need) => need === c.value
                );
                return (
                  <div
                    onClick={() => addOrRemoveAdditionalNeed(c)}
                    key={i}
                    className={"container"}
                    style={{
                      height: 122,
                      width: 122,
                      backgroundColor: isSelected
                        ? Palette.blueTranslucent
                        : Palette.white,
                      border: `1px solid ${
                        isSelected ? Palette.blue : Colors.input.border
                      }`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 10,
                      marginBottom: 10,
                      borderRadius: 5,
                      cursor: "pointer"
                    }}
                  >
                    <img
                      src={c.icon}
                      style={{ width: 32, height: 32, marginBottom: 10 }}
                    />
                    <div style={{ textAlign: "center", color: "#02196C" }}>
                      {c.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
