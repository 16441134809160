import React from "react";
import Checkbox from "../Checkbox";
import Dropdown from "../Dropdown";

const TransportRegulationDropDown = ({
  isDropDownOpen,
  setIsDropDownOpen,
  containerStyle,
  selected = [],
  setSelected,
  data = []
}) => {
  const handleInputChange = (status) => {
    const isAlreadySelected = selected.some(
      (item) => item.value[0] === status.value[0]
    );

    if (status.value[0] === "All") {
      // If "All" is clicked, deselect all others and only select "All"
      if (isAlreadySelected) {
        setSelected([]);
      } else {
        setSelected([status]);
      }
    } else {
      // For other options, uncheck "All" if selected
      const updatedSelected = isAlreadySelected
        ? selected.filter((item) => item.value[0] !== status.value[0])
        : [...selected.filter((item) => item.value[0] !== "All"), status];

      setSelected(updatedSelected);
    }
  };

  return (
    <Dropdown
      visible={isDropDownOpen}
      containerStyle={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        maxWidth: 186,
        top: 100,
        margin: "auto",
        left: 131,
        right: 0,
        ...containerStyle
      }}
    >
      {data
        ?.filter((status) => status.value[0] !== "title")
        ?.map((status, i) => {
          const isLast = i === data.length - 1;
          const isChecked = selected.some(
            (item) => item.value[0] === status.value[0]
          );

          return (
            <div
              onClick={() => handleInputChange(status)}
              className="containerRow"
              style={{
                cursor: "pointer",
                marginBottom: isLast ? 0 : 8,
                alignItems: "center"
              }}
              key={status.value[0]}
            >
              <Checkbox
                value={isChecked}
                onChange={() => handleInputChange(status)}
                name={status.name}
                style={{ marginRight: 8 }}
              />
            </div>
          );
        })}
    </Dropdown>
  );
};

export default TransportRegulationDropDown;
