import { Employees, formatDoc } from "@services/Firebase";
import firebase from "firebase/compat/app";

function generateId() {
  return firebase.firestore().collection("employees").doc().id;
}
export async function addEmployee(employee) {
  return await Employees.add(employee);
}

export async function getEmployee(id) {
  const snap = await Employees.doc(id).get();
  return formatDoc(snap);
}

export async function updateEmployee(id, employee) {
  return await Employees.doc(id).update(employee);
}

export async function deleteEmployee(id) {
  return await Employees.doc(id).delete();
}
