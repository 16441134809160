import { useZustandStore } from "@helpers/store";
import { useClickOutside } from "@hooks/useClickOutside";
import React, { useRef } from "react";
import PatientCard from "./PatientCard";
import RegulationModalTransportCard from "./RegulationModalTransportCard";
import TexteScroller from "./TexteScroller";
function RegulationModal({
  isOpen,
  transport,
  handleClick,
  onCancelOrBourse,
  onAccept,
  onAssign,
  onRefuse,
  close
}) {
  const { patient, id, regulatedBy, driverStatus, isAssign, status } =
    transport ?? {};
  const { carType, name } = transport ?? "";
  const ref = useRef(null);
  const globalRef = useZustandStore((state) => state.globalRef);
  const reverseGeoCodingAdress = useZustandStore(
    (state) => state.reverseGeoCodingAdress
  );
  useClickOutside(ref, handleClick, globalRef);
  const getTransportLabel = (transport, carType) => {
    if (transport && carType === "AMBULANCE")
      return { label: "AMB", color: "#4951FF" };
    if (transport && carType === "VSL")
      return { label: "VSL", color: "#FFB960" };
    return null;
  };
  const transportInfo = getTransportLabel(transport, carType);
  return (
    <div
      ref={ref}
      className="bg-background z-10  rounded-3xl border-solid border-4 border-borderV2 px-5 box-border relative custom-scrollbar"
      style={{
        display: `${isOpen ? "block" : "none"}`,
        width: "350px",
        height: "fit-content",
        top: 50
      }}
    >
      <div className="w-full pt-5  rounded-3xl flex flex-row  items-start mb-[0.75rem]">
        <div className="w-2/12 m-auto text-center">
          <div className="w-[35px]">
            {carType === "VSL" ? (
              <img
                className="m-auto"
                width={30}
                src="/icons/veicule_info_modal/vslcar.svg"
              />
            ) : (
              <img
                className="m-auto"
                width={20}
                src="/icons/veicule_info_modal/ambulance.svg"
              />
            )}
          </div>

          <div className="w-[35px]">
            <p
              style={{
                marginRight: 4,
                fontSize: 13,
                fontStyle: "italic",
                alignSelf: "flex-end",
                textAlign: "center",
                margin: "auto",
                lineHeight: 0.8,
                fontWeight: 600,
                color: transportInfo?.color || "inherit"
              }}
            >
              {transportInfo?.label}
            </p>
          </div>
        </div>
        <div className="w-6/12 flex flex-col">
          <div style={{ lineHeight: 1 }} className="flex w-full justify-start">
            <p
              style={{
                fontSize: 15,
                fontWeight: "bold",
                color: "#4951FF",
                whiteSpace: "nowrap"
              }}
            >
              <span className="text-nightBlue">Transport </span>
              {transport && transport.id.substring(0, 7)}
            </p>
          </div>
          <div
            className="flex justify-start w-full items-end"
            style={{ lineHeight: 1 }}
          >
            <p
              style={{
                fontSize: 12,
                color: "#02196C60",
                fontWeight: "bold",
                height: "fit-content",
                fontStyle: "italic",
                whiteSpace: "nowrap"
              }}
            >
              <TexteScroller className="w-[230px] pr-1">
                Proposé par -
                <span style={{ color: "#DB2C73" }}>Groupe Hostal </span>
              </TexteScroller>
            </p>
          </div>
        </div>
        <div className="cursor-pointer" onClick={() => close()}>
          <img
            width={20}
            height={20}
            src="icons/transport_tracking/close.svg"
            alt=""
          />
        </div>
      </div>
      {transport && (
        <>
          <div style={{ width: "302px" }} className="rounded-3xl">
            <PatientCard patient={patient} />
          </div>
          <div className="rounded-3xl m-auto">
            {<RegulationModalTransportCard transport={transport} />}
          </div>
        </>
      )}
      <div className="mb-3">
        {regulatedBy === "BOURSE" && !isAssign && (
          <div className="flex justify-around">
            <button
              onClick={() => onAccept(transport)}
              className="bg-skyBlue text-white w-[105px] h-[34px] text-[11px] font-semibold rounded-[3px]"
              containerStyle={{ marginRight: 5 }}
            >
              Accepter
            </button>

            <button
              onClick={() => onRefuse(transport)}
              className="bg-pink text-white w-[105px] h-[34px] text-[11px] font-semibold rounded-[3px]"
            >
              Refuser
            </button>
          </div>
        )}

        {regulatedBy !== "BOURSE" && isAssign && driverStatus === "PENDING" ? (
          <div className="flex justify-around">
            <button
              onClick={() => onAssign(transport)}
              className="bg-nightBlue text-white w-[105px] h-[34px] text-[11px] font-semibold rounded-[3px]"
            >
              Réattribuer
            </button>

            <button
              onClick={() => onCancelOrBourse(transport)}
              className="bg-pink text-white w-[105px] h-[34px] text-[11px] font-semibold rounded-[3px]"
            >
              Annuler
            </button>
          </div>
        ) : (
          (regulatedBy !== "BOURSE" && !isAssign && (
            <div className="flex justify-around">
              <button
                onClick={() => {
                  onAssign(transport);
                }}
                className="bg-nightBlue text-white w-[105px] h-[34px] text-[11px] font-semibold rounded-[3px]"
              >
                Attribuer
              </button>

              <button
                onClick={() => onCancelOrBourse(transport)}
                className="bg-pink text-white w-[105px] h-[34px] text-[11px] font-semibold rounded-[3px]"
              >
                Annuler
              </button>
            </div>
          )) ??
          null
        )}
      </div>
      {/* {transport.isAssign === false && regulatedBy !== "BOURSE" ? (
        <button
          onClick={() => onAssign(transport)}
          fullWidth
          text="Assigner"
          containerStyle={{ marginRight: 5 }}
        />
      ) : transport.isAssign === true &&
        regulatedBy !== "BOURSE" &&
        transport.status !== "IN_PROGRESS" ? (
        <button
          onClick={() => onAssign(transport)}
          fullWidth
          text="Réassigner"
          containerStyle={{ marginRight: 5 }}
        />
      ) : (
        transport && (
          <button
            onClick={() => onAccept(transport)}
            fullWidth
            text="Accepter"
            variant={"primary"}
            containerStyle={{ marginRight: 5 }}
          />
        )
      )} */}
    </div>
  );
}

export default RegulationModal;
