import { useZustandStore } from "@helpers/store";
import moment from "moment";
import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { useGlobal } from "reactn";
import TransportRegulationCard from "./TransportRegulationCard";

function TransportRegulationList({ transports, selected }) {
  const [transportSelected, setTransportSelected] =
    useGlobal("transportSelected");
  const [selectedTransport, setSelectedTransport] = useState({
    id: null,
    isSelected: false
  });

  const setRegulatedTransport = useZustandStore(
    (state) => state.setRegulatedTransport
  );
  const setIsBourseModalOpen = useZustandStore(
    (state) => state.setIsBourseModalOpen
  );
  const setIsDriverModalOpen = useZustandStore(
    (state) => state.setIsDriverModalOpen
  );
  const setIsOpen = useZustandStore((state) => state.setIsOpen);
  const setGlobalRef = useZustandStore((state) => state.setGlobalRef);
  const setIsRegulationModalOpen = useZustandStore(
    (state) => state.setIsRegulationModalOpen
  );
  const isRegulationModalOpen = useZustandStore(
    (state) => state.isRegulationModalOpen
  );
  const setTransportId = useZustandStore((state) => state.setTransportId);

  const isSelected = (item) => {
    if (!selected || !selected.value || selected.value.length === 0) {
      return true; // Show all items if no selection
    }

    if (Array.isArray(selected.value)) {
      const matchFound = selected.value.some((status) =>
        item.value.includes(status)
      );
      return matchFound;
    }

    return item.value === selected.value;
  };

  // Filter transports based on the selected filter and return transport visibility logic

  const filteredTransports = transports
    .map((transport) => {
      if (transport.goToTransportId) {
        // Handle return transport visibility: show if corresponding Aller is ENDED
        const correspondingAllerTransport = transports.find(
          (t) => t.id === transport.goToTransportId
        );
        if (
          correspondingAllerTransport &&
          correspondingAllerTransport.status === "ENDED"
        ) {
          return { ...transport, hidden: false }; // Show Retour if Aller is ENDED
        }
        return { ...transport, hidden: true }; // Hide Retour if Aller is not ENDED
      }
      return transport; // Return the transport as-is if no return transport
    })
    .filter((transport) => !transport.hidden) // Exclude hidden transports
    .filter((item) => isSelected(item)); // Apply selected filter to show items

  // Handle transport item click
  const handleTransportClick = (item, e) => {
    if (item.id !== selectedTransport.id) {
      setRegulatedTransport(item);
      setTransportSelected(item);
      setGlobalRef(e.target);
      setIsRegulationModalOpen(true);
      setIsBourseModalOpen(false);
      setIsOpen(false);
      setIsDriverModalOpen([false, false]);
      setTransportId(item.id);
      setSelectedTransport({ id: item.id, isSelected: true });
    } else {
      setIsRegulationModalOpen(true);
    }
  };
  const filteredTransports2 = filteredTransports.reduce((acc, value) => {
    if (!acc.includes(value)) acc.push(value);
    return acc;
  }, []);
  return (
    <div style={{ height: "410px" }} className="custom-scrollbar">
      {filteredTransports2.length > 0
        ? filteredTransports2
            .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
            .map((item, i) => {
              const prevStartDate = i > 0 ? transports[i - 1].startDate : null;
              const isSameAsPrevDay =
                prevStartDate &&
                moment(prevStartDate).isSame(item.startDate, "d");

              return (
                <div
                  onClick={(e) => handleTransportClick(item, e)}
                  style={{ width: "320px" }}
                  key={item.id}
                >
                  {!isSameAsPrevDay && (
                    <VisibilitySensor offset={{ top: 300 }} partialVisibility>
                      <div
                        className="capitalize"
                        id={moment(item.startDate).format("YYYY-MM-DD")}
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          marginBottom: 5,
                          marginTop: i !== 0 ? 5 : 0,
                          color: "#02196C"
                        }}
                      >
                        {moment(item.startDate).format("dddd DD MMMM YYYY")}
                      </div>
                    </VisibilitySensor>
                  )}
                  <TransportRegulationCard
                    key={item.id}
                    transport={item}
                    selectedTransport={selectedTransport}
                  />
                </div>
              );
            })
        : null}
    </div>
  );
}

export default TransportRegulationList;
